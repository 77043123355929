<template>
	<div>
		<div class="child-top">
			<div class="input-from">
				<el-select class="form-select" clearable placeholder="请选择设备分类" filterable v-model="deviceClass" @change="getData">
					<el-option v-for="item in classList" :label="item.name" :value="item.uuid" :key="item.uuid"></el-option>
				</el-select>

				<!-- <el-button @click="getData(true)" slot="append" class="searchBtn" round>搜索</el-button> -->
			</div>
			<div class="operation-button">
				<el-button @click="add_btn" class="new_btn" round>
					<span>新增</span>
				</el-button>
			</div>
		</div>
		<div style="background: #fff" class="">
			<div class="cate_list_view">
				<div v-for="item in ifcationList" class="cate_list_content">
					<div class="cate_list_title">
						<div class="cate_list_name">
							{{ item.name }}
						</div>
						<i class="el-icon-edit" @click="editInfo(item)"></i>
						<i class="el-icon-delete" @click="delInfo(item)"></i>
					</div>
					<ul @click="goDetail(item)">
						<li v-for="itm in item.hardware_arr"><span></span>{{ itm }}</li>
					</ul>
				</div>
			</div>

			<el-pagination
				:current-page.sync="currentPage"
				:page-size="page_size"
				:total="total"
				:key="elementui_page_component_key"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>

		<el-dialog :title="editShowTitle" :visible.sync="dialogVisible" center width="30%" :show-close="false" :close-on-click-modal="false">
			<div class="brand_view"><i>*</i> 设备分类: <el-input v-model="newBrandName" style="width: 80%" maxlength="20"></el-input></div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="inner_save">保 存</el-button>
				<el-button @click="inner_cancel">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			deviceClass: '',
			classList: [],
			ifcationList: [],
			currentPage: 1,
			page_size: 12,
			total: 0,
			editShowTitle: '',
			dialogVisible: false,
			newBrandName: '',
			newBrandUuid: '',
			elementui_page_component_key: 0,
		}
	},
	methods: {
		add_btn() {
			this.editShowTitle = '新增分类'
			this.dialogVisible = true
		},
		handleCurrentChange(val) {
			this.currentPage = val
			this.getData()
		},
		goDetail(items) {
			sessionStorage.setItem('arch_search', JSON.stringify({ currentPage: this.currentPage, deviceClass: this.deviceClass }))

			this.$router.push({ path: '/management/PhysEquipment', query: { cate_uuid: items.uuid } })
		},
		editInfo(items) {
			this.editShowTitle = '编辑分类'
			this.dialogVisible = true
			this.newBrandName = items.name
			this.newBrandUuid = items.uuid
		},
		delInfo(items) {
			this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.$axios
						.post(
							'/p/equipment_hardware/cateDelete',
							this.$qs({
								uuids: items.uuid,
							})
						)
						.then(res => {
							if (res.data.code == 0) {
								this.$message({
									type: 'success',
									message: res.data.message,
								})
								this.getData()
							}
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
					})
				})
		},
		getData() {
			this.$axios
				.post(
					'/p/equipment_hardware/cateLists',
					this.$qs({
						type: 1,
						page: this.currentPage,
						page_size: 12,
						name: '',
						cate_uuid: this.deviceClass,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.ifcationList = res.data.data.rows
						this.total = res.data.data.total
					}
				})
		},
		inner_save() {
			var url = '/p/equipment_hardware/cateAdd',
				obj = {
					type: 1,
					name: this.newBrandName,
				}
			if (this.editShowTitle == '编辑分类') {
				url = '/p/equipment_hardware/cateUpdate'
				obj.uuid = this.newBrandUuid
			}
			this.$axios.post(url, this.$qs(obj)).then(res => {
				if (res.data.code == 0) {
					this.$message({
						type: 'success',
						message: res.data.message,
					})
					this.dialogVisible = false
					this.newBrandName = ''
					this.getData()
				} else {
					this.$message({
						type: 'error',
						message: res.data.message,
					})
				}
			})
		},
		inner_cancel() {
			this.dialogVisible = false
			this.newBrandName = ''
		},
	},
	mounted() {
		var obj = JSON.parse(sessionStorage.getItem('arch_search'))
		if (obj) {
			this.deviceClass = obj.deviceClass
			this.currentPage = obj.currentPage
			this.elementui_page_component_key++
		}

		this.getData()
		this.$axios
			.post(
				'/p/equipment_hardware/cateListAll',
				this.$qs({
					type: 1,
				})
			)
			.then(res => {
				if (res.data.code == 0) {
					this.classList = res.data.data.rows
				}
			})
	},
}
</script>

<style lang="scss" scoped>
.child-top {
	margin-top: 10px;
	.input-from {
		.searchBtn {
			margin-top: 10px;
		}
		.form-select {
			margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}
.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}

.cate_list_view {
	display: flex;
	flex-wrap: wrap;
	// justify-content: space-between;
	padding: 20px 20px 0 20px;
	.cate_list_content {
		width: 23%;
		min-height: 100px;
		border-radius: 10px;
		box-shadow: 0px 0px 5px 2px #0055e9;
		margin-bottom: 30px;
		margin-right: 2.5%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		&:hover {
			cursor: pointer;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
		.cate_list_title {
			height: 40px;
			line-height: 40px;
			text-align: center;
			background: #4487fa;
			color: #fff;
			font-size: 16px;
			position: relative;
			&:hover {
				i {
					display: inline-block;
				}
			}
			.cate_list_name {
				width: 200px;
				margin: 0 auto;
				white-space: nowrap; //不换行
				overflow: hidden; //超出隐藏
				text-overflow: ellipsis; //超出文本省略号
			}
			i {
				font-size: 18px;
				float: right;
				position: absolute;
				top: 10px;
				display: none;
				&:nth-child(2) {
					right: 50px;
				}
				&:last-child {
					right: 20px;
					color: #eb1e44;
				}
			}
		}
		> ul {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			padding: 20px 20px 10px 20px;
			li {
				width: 50%;
				font-size: 16px;
				margin-bottom: 10px;
				span {
					width: 10px;
					height: 10px;
					display: inline-block;
					background: #ff9d0a;
					border-radius: 50%;
					margin-right: 5px;
				}
			}
		}
	}
}

.table-pagination {
	padding-bottom: 20px;
}
.brand_view {
	text-align: center;
	i {
		color: #f00;
	}
}
</style>
